import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Header from './header';
import Nav from './nav';
import RouterBreadcrumbs from './breadcrumbs/breadcrumbs';
import navConfig from './nav/config';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const DashboardLayout = ({ children }) => {
  const [openNav, setOpenNav] = useState(false);

  const handleOpenNav = () => {
    setOpenNav(true);
  };

  const handleCloseNav = () => {
    setOpenNav(false);
  };

  return (
    <StyledRoot>
      <Header onOpenNav={handleOpenNav} />
      <Nav navConfig={navConfig} openNav={openNav} onCloseNav={handleCloseNav} />
      <Main>
        <RouterBreadcrumbs navConfig={navConfig}/>
        {children}
      </Main>
    </StyledRoot>
  );
};


export default DashboardLayout;



