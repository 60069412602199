import React, { useState, useCallback, useEffect, useRef } from 'react';
import _ from 'lodash';
import {
  styled,
  Box,
  Modal,
  Input,
  Slide,
  IconButton,
  InputAdornment,
  ClickAwayListener,
} from '@mui/material';
import Iconify from '../../../components/iconify';
import ProductCard from './ProductSearch';
import StoreCard from './StoreSearch';
import config from '../../../config/config';
import { useApiContext } from '../../../contexts/api';

const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 92;

const StyledSearchbar = styled('div')(({ theme, open }) => ({
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: HEADER_MOBILE,
  padding: theme.spacing(0, 3),
  boxShadow: open ? theme.customShadows.z8 : 'none',
  [theme.breakpoints.up('md')]: {
    height: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const EscButtonContainer = styled(Box)({
  marginLeft: 'auto',
  marginRight: '12%',
});

const EscButton = styled(IconButton)(({ theme }) => ({
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: '4px',
  width: 40,
  height: 30,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#c7ffd6',
  '& span': {
    color: '#000000',
    fontSize: '12px',
  },
}));

const StyledModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  overflow: 'auto',
  '& .MuiBackdrop-root': {
    height: '100%',
  },
}));

const StyledContainer = styled(Box)({
  backgroundColor: '#c7ffd6',
  padding: '0',
  height: '100%',
  display: 'flex',
  width:'70%',
  alignItems: 'center',
  justifyContent: 'center',
});

const ScrollableProductContainer = styled(Box)(({ theme }) => ({
  maxHeight: 'calc(100vh - 10px)', // Adjust the maxHeight to your desired value
  overflowY: 'auto',
  position: 'relative',
  padding: '16px',
  '&::-webkit-scrollbar': {
    width: 2,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 4,
  },
  '&:after': {
    content: '""',
    position: 'sticky',
    top: 0,
    left: 0,
    height: HEADER_MOBILE,
    pointerEvents: 'none',
    zIndex: 1,
    background: 'white',
    backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))',
  },
}));


const StyledProductCard = styled(ProductCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

const StyledStoreCard = styled(StoreCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

export default function Searchbar() {
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedStore, setSelectedStore] = useState(null);
  const { postRequest } = useApiContext();

  const searchbarRef = useRef(null);

  const handleOpen = () => {
    setOpen(true);
    setOpenModal(false); // Close the product container modal when opening the search bar
  };

  const handleClose = () => {
    setOpen(false);
    setOpenModal(false); // Close the product container modal when closing the search bar
    setSelectedProduct(null);
    setSelectedStore(null);
  };

  const handleFileOpen = (item) => {
    if (item.ProductIdentifier === 'product') {
      setSelectedProduct(item);
    } else if (item.SiteIdentifier === 'store') {
      setSelectedStore(item);
    }
  };

  const debouncedSearch = useCallback(
    _.debounce(async (value) => {
      try {
        const response = await postRequest(config.apiUrl, 'search', { query: value });
        setItems(response);
        setOpenModal(true);
      } catch (error) {
        console.error('Error:', error);
      }
    }, 500),
    [postRequest]
  );

  const handleChange = (event) => {
    const { value } = event.target;
    if (value.trim() === '') {
      setItems([]);
      setOpenModal(false);
    } else {
      debouncedSearch(value);
    }
  };

  const handleProductDetailsClose = () => {
    setSelectedProduct(null);
  };

  const handleStoreDetailsClose = () => {
    setSelectedStore(null);
  };

  const handleEscButton = () => {
    handleClose();
  };

  const handleEscKeyPress = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        // ESC key pressed
        handleClose();
      }
    },
    []
  );

  useEffect(() => {
    document.addEventListener('keydown', handleEscKeyPress);
    return () => {
      document.removeEventListener('keydown', handleEscKeyPress);
    };
  }, [handleEscKeyPress]);

  useEffect(() => {
    const handleClickAway = (event) => {
      if (searchbarRef.current && !searchbarRef.current.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener('mousedown', handleClickAway);
    return () => {
      document.removeEventListener('mousedown', handleClickAway);
    };
  }, []);

  if (selectedProduct) {
    return (
      <ClickAwayListener onClickAway={handleProductDetailsClose}>
        <div>
          <StyledProductCard product={selectedProduct} onOpenFile={handleFileOpen} />
        </div>
      </ClickAwayListener>
    );
  }

  if (selectedStore) {
    return (
      <ClickAwayListener onClickAway={handleStoreDetailsClose}>
        <div>
          <StyledStoreCard store={selectedStore} onOpenFile={handleFileOpen} />
        </div>
      </ClickAwayListener>
    );
  }

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div ref={searchbarRef}>
        <StyledSearchbar open={open} onClick={handleOpen}>
          {!open && (
            <IconButton sx={{ marginLeft: '16px' }}>
              <Iconify icon="eva:search-fill" />
            </IconButton>
          )}
          <Slide direction="down" in={open} mountOnExit unmountOnExit>
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <Input
                autoFocus={open}
                fullWidth
                disableUnderline
                placeholder="Search..."
                onChange={handleChange}
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', marginLeft: '17px' }} />
                  </InputAdornment>
                }
                sx={{ flexGrow: 1, fontWeight: 'fontWeightBold', width: '100%' }}
              />
            </div>
          </Slide>
          {open && (
            <EscButtonContainer>
              <EscButton onClick={handleEscButton}>
                <span style={{ textTransform: 'lowercase' }}>esc</span>
              </EscButton>
            </EscButtonContainer>
          )}
        </StyledSearchbar>
        <StyledModal open={openModal} onClose={handleClose}>
          <StyledContainer>
            <ScrollableProductContainer>
              {items &&
                items.map((item, index) => (
                  <Box key={index} mb={2}>
                    {item.ProductIdentifier ? (
                      <StyledProductCard product={item} onOpenFile={handleFileOpen} />
                    ) : (
                      <StyledStoreCard store={item} onOpenFile={handleFileOpen} />
                    )}
                  </Box>
                ))}
            </ScrollableProductContainer>
          </StyledContainer>
        </StyledModal>
      </div>
    </ClickAwayListener>
  );
}
